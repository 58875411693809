import * as React from "react"
import Website from "@src/components/wrappers/website"
import Nav from "@src/components/marketing/headerAlt"
import Footer from "@components/marketing/footer"

import CTA from "@components/marketing/cta"
import Scott from "@src/images/optimized/team/scott"

const seo = {
  url: "/about",
  title: "Sheetsync: About us",
  description: "Read about Sheetsync and discover what we're all about."
}

export default class About extends React.Component {
  render() {
    return (
      <Website seo={seo} marketing>
        <div className="bg-white">
          <Nav />
        </div>
        <section className="max-w-6xl m-auto text-center px-6 py-10 sm:py-16 md:py-20">
          <h1 className="text-2xl sm:text-4xl md:text-5xl leading-tight font-semibold mb-2 tracking-tight text-gray-900">
            Howdy!
            <span className="mx-1" role="img" aria-label="Waving hand"> 👋 </span>
            My name is Scott.
          </h1>
          <div className="shadow-lg w-48 sm:w-64 mx-auto my-8 sm:my-10 md:my-12 rounded-lg overflow-hidden">
            <Scott />
          </div>
          <h2 className="max-w-2xl mx-auto text-lg sm:text-3xl font-semibold mb-4 sm:mb-10 leading-snug text-gray-900">
            I'm the founder, engineer, designer, and customer support at Sheetsync.
          </h2>
          <div className="max-w-2xl m-auto">
            <p className="text-base sm:text-lg text-gray-600 -mb-6 sm:-mb-12">
              I started Sheetsync because I wanted to manage my finances with both the flexibility of a spreadsheet and the convenience of a budgeting app. You can connect with me via <a className="hover:underline text-blue-900" target="_blank" rel="noopener noreferrer" href="https://twitter.com/scottmpickering">Twitter</a>, <a className="hover:underline text-blue-900" href="mailto:scott@getsheetsync.com">email</a>, or live chat.
            </p>
          </div>
        </section>
        <CTA />
        <Footer />
      </Website>
    )
  }
}
